import TypeIt from "typeit";

new TypeIt("#element", {
  speed: 400,
  lifeLike: true,
  waitUntilVisible: true,
  cursor: false,
})
  .exec(
    () => (document.getElementById("elementFallback").style.display = "none")
  )
  .type("1&#8383; = ? &#36;", { delay: 300 })
  .delete(3, { delay: 300 })
  .type(" 1&#8383;", { delay: 300 })
  .go();
